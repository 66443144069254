<template>
  <div>
    <lottie :options="defaultOptions" v-on:animCreated="handleAnimation" />
  </div>
</template>

<script>
import Lottie from 'vue-lottie'

export default {
  name: 'LottieAnimationComponent',
  components: {
    lottie: Lottie
  },
  // props: ["animation", "animationSpeed"],
  props: {
    animation: {
      default: ''
    },
    loop: {
      default: true
    },
    animationSpeed: {
      default: 1
    },
    playDirection: {
      default: 1
    },
    startPercent: {
      default: 0
    },
    endPercent: {
      default: 100
    }
  },
  data () {
    return {
      defaultOptions: { animationData: this.animation, loop: this.loop }
    }
  },
  methods: {
    handleAnimation: function (anim) {
      var startFrame = 0
      var endFrame = 0
      this.anim = anim
      this.anim.setSpeed(this.animationSpeed)
      startFrame = this.anim.totalFrames * (this.startPercent / 100)
      endFrame = this.anim.totalFrames * (this.endPercent / 100)
      this.anim.setSegment(startFrame, endFrame)
      this.anim.setDirection(this.playDirection)
      if (this.animationSpeed === 2.5) {
        console.log('Lottie: ', this.anim)
      }
    },

    stop: function () {
      this.anim.stop()
    },

    play: function () {
      this.anim.play()
    },

    pause: function () {
      this.anim.pause()
    },
    pauseOnPercent: function (percent) {
      this.anim.goToAndStop((this.anim.totalFrames * percent) / 100, true)
    },
    playOnPercent: function (percent) {
      this.anim.goToAndPlay((this.anim.totalFrames * percent) / 100, true)
    },
    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed)
    },
    demoFunction: function () {
      console.log('Demo function')
      // this.anim.setSpeed(2)
      this.anim.stop()
      this.anim.play()
      this.pauseOnPercent(50)
    }
  }
}
</script>

<style>
</style>
